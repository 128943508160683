import { Component, OnInit, OnDestroy, ViewChild, ViewEncapsulation, ChangeDetectorRef } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Router, NavigationEnd, Event } from "@angular/router";
import { Subscription } from "rxjs";
import { MediaChange, MediaObserver } from "@angular/flex-layout";
import PerfectScrollbar from "perfect-scrollbar";
import { TourService } from "ngx-tour-ng-bootstrap";
import { filter } from "rxjs/operators";
import { MenuItems } from "../core/menu/menu-items/menu-items";
import { PageTitleService } from "../core/page-title/page-title.service";
import { AuthService } from "../service/auth/auth.service";
import { CoreService } from "../service/core/core-service.service";
import { AfterContentChecked } from "@angular/core";
import { GlobalService } from "app/shared/global.service";
import { CommonService } from "app/shared/common.service";
import { CustomizeService } from "app/shared/customize.service";
import { DashboardV1Service } from "app/dashboard/dashboard-v1/dashboard-v1.service";
import { DatePipe } from "@angular/common";
import { ToastrService } from 'ngx-toastr';
import { UserDashboardService } from "app/master/client/user-dashboard/user-dashboard.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

declare var require;

const screenfull = require("screenfull");

@Component({
  selector: "epion-layout",
  templateUrl: "./main-material.html",
  styleUrls: ["./main-material.scss"],
  encapsulation: ViewEncapsulation.None
})

export class MainComponent implements OnInit, OnDestroy, AfterContentChecked {

  datePipe = new DatePipe('en-US');
  dark: boolean;
  boxed: boolean;
  collapseSidebar: boolean;
  compactSidebar: boolean;
  customizerIn: boolean = false;
  chatWindowOpen: boolean = false;
  chatSidebar: boolean = false;
  sidebarClosed: boolean = false;
  chatpanelOpen: boolean = false;
  sidenavOpen: boolean = true;
  isMobile: boolean = false;
  isFullscreen: boolean = false;
  _opened: boolean = true;
  _showBackdrop: boolean = false;
  _closeOnClickOutside: boolean = false;
  showSettings: boolean = false;

  image: "assets/img/user-3.jpg";

  _mode: string = "push";
  sidenavMode: string = "side";
  themeSkinColor: any = "light";
  headerSkinColor: any = "light";
  root: any = "ltr";
  layout: any = "ltr";
  header: string;
  url: string;
  public innerWidth: any;
  input: any;
  miniSidebar: boolean = true;
  private _router: Subscription;
  private _mediaSubscription: Subscription;

  isExpiry = false;
  LicenseValidUpTo = new Date(this.globals.CurrentDateTime);
  currentDate = new Date(this.globals.CurrentDateTime);


  currentLang = "en";
  @ViewChild("sidenav") sidenav;

  constructor(public tourService: TourService,
    private coreService: CoreService,
    private authService: AuthService,
    public menuItems: MenuItems,
    private pageTitleService: PageTitleService,
    public translate: TranslateService,
    private router: Router,
    private media: MediaObserver,
    private cdr: ChangeDetectorRef,
    public globals: GlobalService,
    private dashboardV1Service: DashboardV1Service,
    private modalService: NgbModal,
    private userDashboardService: UserDashboardService,
    private commonService: CommonService,
    private toasterService: ToastrService,
    private customizeService: CustomizeService) {

    const browserLang: string = translate.getBrowserLang();
    translate.use(browserLang.match(/en|fr/) ? browserLang : "en");

    // this.tourService.events$.subscribe();
    // this.tourService.initialize([
    // 	{
    // 		anchorId: "start.tour",
    // 		content: "Welcome to the Epion tour!",
    // 		placement: "below",
    // 		title: "Welcome to Epion",
    // 	},
    // 	{
    // 		anchorId: "tour-search",
    // 		content: "Enjoying Search box with sugestion and many more things",
    // 		placement: "below",
    // 		title: "Search Box",
    // 	},
    // 	{
    // 		anchorId: "tour-full-screen",
    // 		content: "By pressing this button you can switch to fullscreen mode.",
    // 		placement: "below",
    // 		title: "Full Screen",
    // 	},
    // 	{
    // 		anchorId: "tour-ui",
    // 		content: "Create your frontend with beautifully designed UI-Panel. Pre integrated page
    //      templates and widgets provides you the power to create any design.",
    // 		placement: "right",
    // 		title: "Awesome UI-Panel",
    // 	}]
    // );

    // if (window.innerWidth > 1280) {
    // 	this.tourService.start();
    // }
  }

  public HasClientDetails(): boolean {
    return this.globals != null && this.globals.ClientID > 0;
  }

  // Getting Client Details using AuthService
  GetClientDetails() {
    const tokenInfo = this.authService.getDecodedToken();
    this.globals.ClientID = tokenInfo["http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata"];
    this.globals.LocationID = tokenInfo["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/locality"];
    this.globals.LoginTemplate = tokenInfo["role"];
    this.globals.UserID = tokenInfo["nameid"];
    this.globals.UserName = tokenInfo["unique_name"].toLowerCase();
    this.globals.IsDocterzClient = tokenInfo["actort"] == "True" ? true : false;
    this.globals.DefaultStore = tokenInfo["DefaultStore"];
    this.globals.DefaultSubStore = tokenInfo["DefaultSubStore"];
    this.globals.LicenseFor = tokenInfo["LicenseFor"];
    this.globals.HospitalBelongsTo = tokenInfo["HospitalBelongsTo"];
    this.globals.IsClientSuperAdmin = tokenInfo["IsClientAdmin"] == "True" ? true : false;
    this.globals.DoctorScreenAlert = tokenInfo["DoctorScreenAlert"] == "True" ? true : false;
    this.globals.IsDiangnosticDeleteAuthority = tokenInfo["DiangnosticDeleteAuthority"] == "True" ? true : false;
    this.globals.MultiClientLocation = tokenInfo["MultiClientLocation"] == "True" ? true : false;
    this.globals.DietTreatmentType = tokenInfo["DietTreatmentType"];
    this.globals.IsCashlessSuperAdmin = tokenInfo["IsCashlessSuperAdmin"] == "True" ? true : false;
    this.globals.PagenationApplicable = tokenInfo["PagenationApplicable"] == "True" ? true : false;
    this.globals.OpIpQueueDisplay = tokenInfo["OpIpQueueDisplay"] == "True" ? true : false;

    const date = new Date(0);
    this.globals.ExpireTime = new Date(date.setUTCSeconds(tokenInfo["exp"]));

    this.GetAuthentcationsForUserByUserId();
    this.globals.HasClientInfo = true;
  }

  // Getting Atuhentications For User
  GetAuthentcationsForUserByUserId() {
    this.commonService.GetMenuVisbleForUserByUserID(this.globals.UserID)
      .subscribe((data) => {
        this.globals.MenuComponentAuthentication = data;
        this.GetGeneralConfigurationDetails();
        this.GetConfigurationDetails();
        this.GetClientConfigurationDetails();
      });
  }

  // Getting Configuration Details
  GetConfigurationDetails() {
    this.globals.ClientReportPath = "";
    this.globals.ClientSpecialReport = "";
    this.globals.IsMultipleStore = false;
    this.commonService.GetConfigurationDetails(this.globals.ClientID, this.globals.LocationID)
      .subscribe((data) => {
        if (data != null && data.length > 0) {
          this.globals.configurations = data[0];
          this.loadClientReceiptBooks();
          this.GetClientLicenseValidUpTo();
          if (this.globals.configurations['PharmacyName'] == "" && this.globals.configurations['PharmacyGSTNo'] == "") {
            this.globals.IsMultipleStore = true;
          }
          this.globals.ClientReportPath = this.globals.configurations["ReportPath"];
          this.globals.ClientSpecialReport = this.globals.configurations["SpecialReport"];
        }
        this.menuItems.updateMenuItems(this.globals.UserID);
        this.loadMessages();
        this.GetInternalChatCount();
      });
  }

  // Getting General Configuration Details
  GetGeneralConfigurationDetails() {
    this.commonService.GetGeneralConfigurationDetails()
      .subscribe((data) => {
        if (data != null && data.length > 0) {
          this.globals.GeneralConfigurations = data[0];

        }
      });
  }

  // Getting company Configuration Details
  GetClientConfigurationDetails() {
    this.commonService.GetClientConfigurationDetails(this.globals.ClientID, this.globals.LocationID)
      .subscribe((data) => {
        if (data != null && data.length > 0) {
          this.globals.configurationsDetails = data;
        }
      });
  }

  // this function is used to show subscription expiry message to client before 15 days from date of expiry
  GetClientLicenseValidUpTo() {
    this.LicenseValidUpTo = this.globals.configurations["LicenseValidUpTo"];
    let date = new Date(this.LicenseValidUpTo);
    let currentDate = new Date();
    let days = Math.floor((date.getTime() - currentDate.getTime()) / 1000 / 60 / 60 / 24);
    if (days < 15) {
      this.isExpiry = true;
    }
  }

  // Setting Receipt Book Details
  loadClientReceiptBooks() {
    if (this.globals.configurations) {
      // For OPD
      const opdList = this.globals.configurations["OPD"] != undefined
        && this.globals.configurations["OPD"] != "" ? this.globals.configurations["OPD"].split(",") : "";
      if (opdList.length > 0) {
        this.globals.OPDCash = +opdList[0];
        this.globals.OPDCashSettlement = +opdList[1];
        this.globals.OPDInvoice = +opdList[2];
        this.globals.OPDInvoiceSettlement = +opdList[3];
        this.globals.OPDAdvance = +opdList[4];
        this.globals.OPDRefundBill = +opdList[5];
        this.globals.OPDRefundAdvance = +opdList[6];
        this.globals.OPDCashlessDummyBill = +opdList[7];
      }

      // For IPD
      const ipdList = this.globals.configurations["IPD"] != undefined
        && this.globals.configurations["IPD"] != "" ? this.globals.configurations["IPD"].split(",") : "";
      if (ipdList.length > 0) {
        this.globals.IPDCash = +ipdList[0];
        this.globals.IPDCashSettlement = +ipdList[1];
        this.globals.IPDInvoice = +ipdList[2];
        this.globals.IPDInvoiceSettlement = +ipdList[3];
        this.globals.IPDAdvance = +ipdList[4];
        this.globals.IPDRefundBill = +ipdList[5];
        this.globals.IPDRefundAdvance = +ipdList[6];
        this.globals.IPDShortBill = +ipdList[7];
        this.globals.IPDShortBillSettlement = +ipdList[8];
        this.globals.IPDCashlessDummyBill = +ipdList[9];
      }

      // For Sales
      const salesList = this.globals.configurations["Sales"] != undefined
        && this.globals.configurations["Sales"] != "" ? this.globals.configurations["Sales"].split(",") : "";
      if (salesList.length > 0) {
        // Sales
        this.globals.SalesCash = +salesList[0];
        this.globals.SalesInvoice = +salesList[1];
        this.globals.SalesBillSettlement = +salesList[2];
        this.globals.SalesReturn = +salesList[3];
        this.globals.SalesRefund = +salesList[4];

      }

      // For GRN
      const grnList = this.globals.configurations["GRN"] != undefined
        && this.globals.configurations["GRN"] != "" ? this.globals.configurations["GRN"].split(",") : "";
      if (grnList.length > 0) {
        // GRN
        this.globals.GoodsReceiptNote = +grnList[0];
        this.globals.GoodsReceiptNoteReturn = +grnList[1];
        this.globals.GoodsReceiptNotePayment = +grnList[2];
      }

      // For Indent
      const indentList = this.globals.configurations["Indent"] != undefined
        && this.globals.configurations["Indent"] != "" ? this.globals.configurations["Indent"].split(",") : "";
      if (grnList.length > 0) {
        // Indent
        this.globals.Indent = +indentList[0];
        this.globals.AllowNewIndentWOoldIndent = +indentList[1];
      }

      // For Material Issue
      const MaterialIssueList = this.globals.configurations["SubStore"] != undefined
        && this.globals.configurations["SubStore"] != "" ? this.globals.configurations["SubStore"].split(",") : "";
      if (MaterialIssueList.length > 0) {
        // Indent
        this.globals.MaterialIssue = +MaterialIssueList[0];
        this.globals.ReturnFromSubStore = +MaterialIssueList[1];
        this.globals.IssueToPatient = +MaterialIssueList[2];
        this.globals.ReturnFromPatient = +MaterialIssueList[3];
      }

      // For Material Concusme
      const MaterialConsumeList = this.globals.configurations["MaterialConsume"]
        && this.globals.configurations["MaterialConsume"] != "" ? this.globals.configurations["MaterialConsume"].split(",") : "";
      if (MaterialConsumeList.length > 0) {
        // Material Concusme
        this.globals.MaterialConsume = +MaterialConsumeList[0];
      }

      // For StockAdjustment and MrpAdjustment
      const StockAdjustmentList = this.globals.configurations["StockAdjustment"]
        && this.globals.configurations["StockAdjustment"] != "" ? this.globals.configurations["StockAdjustment"].split(",") : "";
      if (StockAdjustmentList.length > 0) {
        // StockAdjustment
        this.globals.StockAdjustment = +StockAdjustmentList[0];
        // MrpAdjustment
        this.globals.MrpAdjustment = +StockAdjustmentList[1];
      }



    }
  }


  // This method is used to display cutomized messages.
  // This method gives call to getMessages function from customizeService
  loadMessages() {
    this.customizeService.getMessages().subscribe((data) => {
      this.globals.messages = data;

      this.SetComponentVisible();
    });
  }

  // Sets Component Visibility
  SetComponentVisible() {
    for (let i = 0; i < this.globals.MenuComponentAuthentication.length; i++) {
      const ComponentName = this.globals.MenuComponentAuthentication[i]["ComponentName"];
      this.globals.VisibleComponentMenu[ComponentName] = {
        "ComponentID": this.globals.MenuComponentAuthentication[i]["ComponentID"],
        "AuthenticationType": this.globals.MenuComponentAuthentication[i]["AuthenticationType"]
      };
    }

    // Setting Component Visible Not In Menu
    this.SetComponentVisibleNotInMenu();
  }

  // Setting Component Visible Not In Menu
  SetComponentVisibleNotInMenu() {

    /* OPD And IPD Both */
    // Registration
    if (this.globals.VisibleComponentMenu["registration"]) {
      this.globals.registrationVisible = true;
    }
    // Admission
    if (this.globals.VisibleComponentMenu["admission"]) {
      this.globals.admissionVisible = true;
    }
    // Visit-mark
    if (this.globals.VisibleComponentMenu["visit-mark"]) {
      this.globals.visitMarkVisible = true;
    }
    // Case Paper
    if (this.globals.VisibleComponentMenu["case-paper"]) {
      this.globals.casePaperVisible = true;
    }

    // Nursing Observation
    if (this.globals.VisibleComponentMenu["nursing-observation"]) {
      this.globals.nursingObservationVisible = true;
    }

    // IPD Refund
    if (this.globals.VisibleComponentMenu["ipd-refund"]) {
      this.globals.IpdrefundVisible = true;
    }

    // OPD Refund
    if (this.globals.VisibleComponentMenu["opd-refund"]) {
      this.globals.OpdrefundVisible = true;
    }

    // Cashless
    if (this.globals.VisibleComponentMenu["cashless"]) {
      this.globals.cashlessVisible = true;
      // this.globals.imageuploadVisible = true;
    }

    // Cashless Dummy Bill
    if (this.globals.VisibleComponentMenu["cashless-dummy-bill"]) {
      this.globals.cashlessDummyBillVisible = true;
    }

    // Pathology Result Entry
    if (this.globals.VisibleComponentMenu["pathology-result-entry"]) {
      this.globals.pathologyResultEntryVisible = true;
    }

    // file upload
    if (this.globals.VisibleComponentMenu["image-upload"]) {
      this.globals.imageuploadVisible = true;
    }

    // Doctor Request
    if (this.globals.VisibleComponentMenu["doctor-request"]) {
      this.globals.doctorRequestVisible = true;
    }

    // package-charges
    if (this.globals.VisibleComponentMenu["package-charges"]) {
      this.globals.packageChargesVisible = true;
    }

    // pharmacy bill updation
    if (this.globals.VisibleComponentMenu["pharmacy-bill-updation"]) {
      this.globals.pharmacyBillUpdationVisible = true;
    }

    /* OPD */

    // Bill
    if (this.globals.VisibleComponentMenu["bill"]) {
      this.globals.billVisible = true;
    }

    // OPD Advance
    if (this.globals.VisibleComponentMenu["opd-advance"]) {
      this.globals.OpdadvanceVisible = true;
    }

    // Session data
    if (this.globals.VisibleComponentMenu["session-data"]) {
      this.globals.SessionDatavisible = true;
    }
    // Ipd Bill Reconciliation
    if (this.globals.VisibleComponentMenu["opd-bill-reconciliation"]) {
      this.globals.opdBillReconciliationVisible = true;
    }

    /* IPD */

    // Bill
    if (this.globals.VisibleComponentMenu["ipd-bill"]) {
      this.globals.ipdBillVisible = true;
    }

    // IPD Advance
    if (this.globals.VisibleComponentMenu["ipd-advance"]) {
      this.globals.IpdadvanceVisible = true;
    }

    // Payment
    if (this.globals.VisibleComponentMenu["ipd-bill-payment"]) {
      this.globals.ipdBillPaymentVisible = true;
    }

    // Discharge
    if (this.globals.VisibleComponentMenu["discharge"]) {
      this.globals.dischargeVisible = true;
    }

    // Bed Transfer
    if (this.globals.VisibleComponentMenu["bed-transfer"]) {
      this.globals.bedTransferVisible = true;
    }

    // IPD Short Bill
    if (this.globals.VisibleComponentMenu["ipd-short-bill"]) {
      this.globals.ipdShortBillVisible = true;
    }

    // pathology Result Entry
    if (this.globals.VisibleComponentMenu["pathology-result-entry"]) {
      this.globals.pathologyResultEntryVisible = true;
    }

    // radiology Result Entry
    if (this.globals.VisibleComponentMenu["radiology-result-entry"]) {
      this.globals.radiologyResultEntryVisible = true;
    }

    // Ipd Bill Reconciliation
    if (this.globals.VisibleComponentMenu["ipd-bill-reconciliation"]) {
      this.globals.ipdBillReconciliationVisible = true;
    }

    // Sales Return
    if (this.globals.VisibleComponentMenu["sales-return"]) {
      this.globals.salesRetunVisible = true;
    }


    // Pharmacy Bill Reconciliation
    if (this.globals.VisibleComponentMenu["pharmacy-bill-reconciliation"]) {
      this.globals.pharmacyBillRecoVisible = true;
    }
    // Goods Receipt Note Return
    if (this.globals.VisibleComponentMenu["goods-receipt-note-return"]) {
      this.globals.goodsReceiptNoteRetunVisible = true;
    }

    // Return From Sub Store Return
    if (this.globals.VisibleComponentMenu["return-from-sub-store"]) {
      this.globals.returnFromSubStoreReturnVisible = true;
    }

    // External Pathology Billing
    if (this.globals.VisibleComponentMenu["external-pathology-billing"]) {
      this.globals.externalPathologyBillingVisible = true;
    }

    // Lupin Pathology Report
    if (this.globals.VisibleComponentMenu["pathology-report"]) {
      this.globals.pathologyReportVisible = true;
    }

    // OPD Report
    if (this.globals.VisibleComponentMenu["opd-reports"]) {
      this.globals.opdReportVisible = true;
    }
    // IPD Report
    if (this.globals.VisibleComponentMenu["ipd-reports"]) {
      this.globals.ipdReportVisible = true;
    }
    // Common Report
    if (this.globals.VisibleComponentMenu["common-reports"]) {
      this.globals.commonReportVisible = true;
    }
    // Inventory Report
    if (this.globals.VisibleComponentMenu["inventory-reports"]) {
      this.globals.inventoryReportVisible = true;
    }
    // patient prescription
    if (this.globals.VisibleComponentMenu["patient-prescription"]) {
      this.globals.patientPrescriptonVisible = true;
    }
    // stock adjustment
    if (this.globals.VisibleComponentMenu["stock-adjustment"]) {
      this.globals.stockAdjustmentVisible = true;
    }

    // Mrp adjustment
    if (this.globals.VisibleComponentMenu["mrp-adjustment"]) {
      this.globals.MrpAdjustmentVisible = true;
    }

    // Payment Reconciliation
    if (this.globals.VisibleComponentMenu["payment-reconciliation"]) {
      this.globals.PaymentReconciliationVisible = true;
    }
    // Vaccination
    if (this.globals.VisibleComponentMenu["vaccination"]) {
      this.globals.VaccinationVisible = true;
    }
    // Paediatric CaseSheet
    if (this.globals.VisibleComponentMenu["paediatric-casesheet"]) {
      this.globals.PaediatricCaseSheetVisible = true;
    }
    // change-password
    if (this.globals.VisibleComponentMenu["change-password"]) {
      this.globals.ChangePasswordVisible = true;
    }
    // internal-chat
    if (this.globals.VisibleComponentMenu["internal-chat"]) {
      this.globals.InternalChatVisible = true;
    }

    // video-conference
    if (this.globals.VisibleComponentMenu["video-conference"]) {
      this.globals.VideoConferenceVisible = true;
    }

    //EHR-Template
    if (this.globals.VisibleComponentMenu["ehr-template"]) {
      this.globals.EHRTemplateVisible = true;
    }
    // case-paper-ophthal
    if (this.globals.VisibleComponentMenu["case-paper-ophthal"]) {
      this.globals.ophthalVisible = true;
    }
    // certificate
    if (this.globals.VisibleComponentMenu["certificate"]) {
      this.globals.CertificateVisible = true;
    }
    // diat-plan
    if (this.globals.VisibleComponentMenu["diat-plan"]) {
      this.globals.DiatPlanVisible = true;
    }
    // Treatment And Diet Dashboard
    if (this.globals.VisibleComponentMenu["treatment-diet-dashboard"]) {
      this.globals.TreatmentAndDietDashboardVisible = true;
    }
    // Patient Treatment And Diet Details
    if (this.globals.VisibleComponentMenu["patient-diet-treatment"]) {
      this.globals.PatientTreatmentAndDietVisible = true;
    }
    // Image Processcing
    if (this.globals.VisibleComponentMenu["image-processing"]) {
      this.globals.imageProcessVisible = true;
    }
    // free hand writing on pdf
    if (this.globals.VisibleComponentMenu["free-hand-writing"]) {
      this.globals.freehandwritingVisible = true;
    }

    if (this.globals.VisibleComponentMenu["grnamendment"]) {
      this.globals.GRNAmendmentVisible = true;
    }
    if (this.globals.VisibleComponentMenu["salesamendment"]) {
      this.globals.SalesAmendmentVisible = true;
    }


    if (this.globals.VisibleComponentMenu["autoservicecharges"]) {
      this.globals.AutoServiceChargesVisible = true;
    }

    if (this.globals.VisibleComponentMenu["policypayswitch"]) {
      this.globals.PolicyPaySwitchVisible = true;
    }

    if (this.globals.VisibleComponentMenu["material-issue-return"]) {
      this.globals.MaterialIssueReturnVisible = true;
    }
  }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
    this.pageTitleService.title.subscribe((val: string) => {
      this.header = val;
    });

    if (+this.globals.ClientID === 0) {
      this.GetClientDetails();
    }

    this._router = this.router.events.pipe(
      filter((event: Event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
        this.url = event.url;

        if (document.querySelector("#sideNavMenu > aside").classList.contains("ng-sidebar--opened")) {
          document.getElementById("toggleButton").click();
        }
      });

    if (this.url != "/session/login" && this.url != "/session/register"
      && this.url != "/session/forgot-password" && this.url != "/session/lockscreen") {
      const elemSidebar = <HTMLElement>document.querySelector(".sidebar-container");

      /** Perfect scrollbar for sidebar menu **/
      if (window.matchMedia(`(min-width: 1280px)`).matches) {
        const ps = new PerfectScrollbar(elemSidebar, {
          wheelSpeed: 2,
          wheelPropagation: true,
          minScrollbarLength: 20
        });
        ps.update();
      }

      /** Perfect scrollbar for chat window **/
      const elemChatbar = <HTMLElement>document.querySelector(".chat-inner ");
      if (window.matchMedia(`(min-width: 1280px)`).matches) {
      }

      this._toggleOpened();
    }

    if (this.media.isActive("xs") || this.media.isActive("sm") || this.media.isActive("md")) {
      this._mode = "over";
      this._closeOnClickOutside = true;
      this._showBackdrop = true;
      this._opened = false;
      this.sidebarClosed = false;
    }

    this._mediaSubscription = this.media.media$.subscribe((change: MediaChange) => {
      const isMobile = (change.mqAlias == "xs") || (change.mqAlias == "sm") || (change.mqAlias == "md");

      this.isMobile = isMobile;
      this._mode = (isMobile) ? "over" : "push";
      this._closeOnClickOutside = (isMobile) ? true : false;
      this._showBackdrop = (isMobile) ? true : false;
      this._opened = !isMobile;
      this.sidebarClosed = false;
    });


    // Add class on focus of search box in header
    // document.getElementById("search-field").addEventListener("focusin", function () {
    //   document.getElementById("search-field").parentElement.classList.add("search-active");
    // });

    // document.getElementById("search-field").addEventListener("focusout", function () {
    //   document.getElementById("search-field").parentElement.classList.remove("search-active");
    // });
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  GetDashboardpage() {
    this.router.navigate(['/dashboard']);
  }

  ngOnDestroy() {
    this._router.unsubscribe();
    this._mediaSubscription.unsubscribe();
  }

  /**
    * toggleFullscreen method is used to show a template in fullscreen.
    */
  toggleFullscreen() {
    if (screenfull.enabled) {
      screenfull.toggle();
      this.isFullscreen = !this.isFullscreen;
    }
  }

  /**
    * _toggleOpened method is used to toggle a menu list.
    */
  public _toggleOpened() {
    this._opened = !this._opened;
    this.sidebarClosed = !this.sidebarClosed;
  }

  /**
    * customizerFunction is used to open and close the customizer.
    */
  customizerFunction() {
    this.customizerIn = !this.customizerIn;
  }

  /**
    * chatWindowFunction is used to open and close the chat window.
    */
  chatWindowFunction() {
    this.chatWindowOpen = !this.chatWindowOpen;
  }

  /**
    * chatSidebarFunction is used to open and close the chat sidebar list.
    */
  chatSidebarFunction() {
    this.chatSidebar = !this.chatSidebar;
  }

  /**
    * changeThemeColor function filter the color for sidebar section.
    */
  changeThemeColor(color) {
    this.themeSkinColor = color;
  }

  /**
    * changeHeaderColor function filter the color for header section.
    */
  changeHeaderColor(color) {
    this.headerSkinColor = color;
  }

  /**
    * As router outlet will emit an activate event any time a new component is being instantiated.
    */
  onActivate(scrollContainer) {
    scrollContainer.scrollTop = 0;
  }

  /**
    * changeRTL method is used to change the layout of template.
    */
  changeRTL(isChecked) {
    if (isChecked) {
      this.layout = "rtl";
      this.coreService.rtlShowStatus = true;
    } else {
      this.layout = "ltr";
      this.coreService.rtlShowStatus = false;
    }
  }

  /**
    * toggleSidebar method is used to switch between Icon sidebar and Sidebar.
    */
  toggleSidebar(isChecked) {
    this.miniSidebar = !isChecked;
    if (isChecked) {
      document.getElementById("showSidebar").classList.remove("icon-sidebar");
      document.getElementsByClassName("app")[0].classList.remove("icon-sidebar-wrap");
      document.getElementById("boxed-layout").classList.remove("disabled-checkbox");
    } else {
      document.getElementById("showSidebar").className += " icon-sidebar";
      document.getElementsByClassName("app")[0].className += " icon-sidebar-wrap";
      document.getElementById("boxed-layout").className += " disabled-checkbox";
    }
  }

  /**
    * logOut method is used to log out the  template.
    */
  logOut() {
    this.authService.logOut();
  }

  /**
    *closeOnClickOutside method is used when click on outside close/open the sidebar.
    */
  closeOnClickOutside() {
    if (this._opened) {
      this.sidebarClosed = false;
    } else {
      this.sidebarClosed = true;
    }
    return this._closeOnClickOutside;
  }

  /**
    * boxedLayout method is used to show the box layout.
    */
  boxedLayout(isChecked) {
    if (isChecked) {
      this.boxed = true;
      document.getElementById("icon-sidebar").className += " disabled-checkbox";
    } else {
      this.boxed = false;
      document.getElementById("icon-sidebar").classList.remove("disabled-checkbox");
    }
  }

  isInProgress: boolean = false;
  Useritems = [];
  messages: any = {};
  commentMessage = '';
  user = [];
  itemToEdit = {
    'InternalChatID': 0,
    'FromUserID': 0,
    'Date': '',
    'ToUserID': 0,
    'ToUserName': '',
    'FromUserName': '',
    'LocationID': 0,
    'ClientID': 0,
    'UserID': 0,
    'Activate': true,
    'IsChanged': false,
    'MsgItems': [{
      'InternalChatDetailsID': 0,
      'InternalChatID': 0,
      'Date': '',
      'Message': '',
      'Type': '',
      'Activate': true,
    }]
  };

  height = '2rem';
  changeHeight(textArea) {
    this.height = null;
    this.height = textArea.scrollHeight + "px";
  }

  // Chat Pop Up Function 
  AddInternalChatPopUp(content) {
    this.modalService.open(content, {
      windowClass: "myCustomModalClass1", backdrop: 'static',
      size: 'lg', centered: true, keyboard: false
    });
    this.loadUser();
    this.GetAllUsersList();
  }



  showingName = '';

  // This method is used when AddNew button is clicked, to add new form/Patient.
  AddNewInternalChat(InternalChatID, FromUser) {
    this.showingName = FromUser;


    if (InternalChatID == 0) {
      this.itemToEdit = {
        'InternalChatID': 0,
        'FromUserID': 0,
        'Date': '',
        'ToUserID': 0,
        'ToUserName': '',
        'FromUserName': '',
        'LocationID': 0,
        'ClientID': 0,
        'UserID': 0,
        'Activate': true,
        'IsChanged': false,
        'MsgItems': [{
          'InternalChatDetailsID': 0,
          'InternalChatID': 0,
          'Date': '',
          'Message': '',
          'Type': '',
          'Activate': true,
        }]
      };
      this.GetAllUsersList();
    } else {
      this.dashboardV1Service.getInternalChatById(InternalChatID).subscribe(data => {

        this.itemToEdit.MsgItems = data;
        if (this.itemToEdit.MsgItems.length != 0) {
          this.itemToEdit.InternalChatID = data[0]["InternalChatID"];
          this.itemToEdit.FromUserID = data[0]["FromUserID"];
          this.itemToEdit.ToUserID = data[0]["ToUserID"];
          this.itemToEdit.ToUserName = data[0]["ToUserName"];
          this.itemToEdit.FromUserName = data[0]["FromUserName"];

          this.itemToEdit.MsgItems = data;

          if (this.globals.UserID != this.itemToEdit.FromUserID) {
            for (let i = 0; i < this.itemToEdit.MsgItems.length; i++) {
              if (this.itemToEdit.MsgItems[i].Type == "R") {
                this.itemToEdit.MsgItems[i].Type = "S";
              } else {
                this.itemToEdit.MsgItems[i].Type = "R";
              }
            }
          }
        }
      });
    }
  }

  // If Any changes had done on form
  FormChanged(): string {
    let text = "";

    if (text != "") {
      this.toasterService.warning(text);
    }
    return text;
  }

  // Internal Chat Save Function
  saveUser() {
    // Getting Token Expires Or Not
    const tokenExpires = this.authService.isTokenExpired();
    if (!tokenExpires) {
      const error = this.FormChanged();
      if (error == '') {

        const datePipe = new DatePipe('en-US');

        this.itemToEdit.ClientID = this.globals.ClientID;
        this.itemToEdit.LocationID = this.globals.LocationID;

        if (this.itemToEdit.InternalChatID == 0) {
          this.itemToEdit.FromUserID = this.globals.UserID;
          if (this.itemToEdit.ToUserID != 0) {
            this.itemToEdit.ToUserName = this.user.filter((x) => x["UserID"] == this.itemToEdit.ToUserID)[0]["UserName"];
          }
        }
        this.isInProgress = true;

        if (this.globals.UserID != this.itemToEdit.FromUserID) {
          for (let i = 0; i < this.itemToEdit.MsgItems.length; i++) {
            if (this.itemToEdit.MsgItems[i].InternalChatDetailsID == 0) {
              this.itemToEdit.MsgItems[i].Type = "R";
            }
          }
        } else {
          for (let i = 0; i < this.itemToEdit.MsgItems.length; i++) {
            if (this.itemToEdit.MsgItems[i].InternalChatDetailsID == 0) {
              this.itemToEdit.MsgItems[i].Type = "S";
            }

          }
        }

        this.isInProgress = true;
        this.dashboardV1Service.saveUser(this.itemToEdit).subscribe(data => {

          let res = data.split("#");

          if (res[0] == "OK") {
            this.loadUser();

            this.isInProgress = true;
            this.toasterService.success("Record Saved Successfully!!");
            this.AddNewInternalChat(res[1], this.showingName);
            this.GetAllUsersList();
          } else {
            this.toasterService.error("Something went wrong!");
          }
          this.isInProgress = false;
        });
      }
    } else {
      this.saveUser();
    }
    this.height = null;
  }

  //sendComment method is used to send the comment while Internal Chat.
  sendComment(index) {
    if (this.commentMessage != '') {
      let item = {

        'InternalChatDetailsID': 0,
        'InternalChatID': 0,
        'Date': '',
        Message: this.commentMessage,
        'Type': 'S',
        'Activate': true,
      }
      this.itemToEdit.MsgItems.push(item);
      this.commentMessage = '';
      this.saveUser();
    }
  }

  loadUser() {

    this.dashboardV1Service.getUser(this.globals.ClientID, this.globals.ComponentID, this.globals.LocationID, this.globals.UserID).subscribe((data) => {
      this.Useritems = data;
      if (this.Useritems.length > 0) {
        this.AddNewInternalChat(this.Useritems[0]["InternalChatID"], this.Useritems[0]["EmployeeName"]);
      } else {
        this.itemToEdit.InternalChatID = 0;
        this.itemToEdit.MsgItems = [];
      }
    });
  }


  GetSelectedUser() {

    if (this.itemToEdit.ToUserID != 0) {
      this.showingName = this.user.filter((x) => x["UserID"] == this.itemToEdit.ToUserID)[0]["EmployeeName"];
      this.itemToEdit.MsgItems = [];
      this.itemToEdit.InternalChatID = 0;
    }

  }

  // Getting all user list for client location
  GetAllUsersList() {
    this.userDashboardService.GetAllUserListMsg(this.globals.ClientID, this.globals.LocationID, this.globals.UserID).subscribe((data) => {
      this.user = data as string[];
    });
  }

  DeactivateById(InternalChatID, InternalChatDetailsID) {
    const conf = confirm(this.messages['cnfm_Delete']);
    if (conf) {
      this.dashboardV1Service.DeactivateInternalChat(InternalChatID, InternalChatDetailsID).subscribe((data) => {
        if (data == 200) {
          this.loadUser();
          this.toasterService.success("Record deactivated successfully.");
        } else
          this.toasterService.error("Something went wrong!");

      });
    }
  }

  GetInternalChatCount() {
    let Count;
    this.globals.ChangeInternalChatCount = false;
    this.dashboardV1Service.GetInternalChatCount(this.globals.UserID).subscribe((data) => {
      Count = data;
      this.globals.MaxInternalChatDetailsID = Count;
      if (this.globals.MaxInternalChatDetailsID > Count) {
        this.globals.ChangeInternalChatCount = true;
      }
    });
  }

  RouteVideoConferenceRoom(CaseDetailsID) {
    if (CaseDetailsID != 0) {

    } else {
      let link = "https://video.epionhealth.in/#/video-conference/home?UserName=" + this.globals.UserName + "?CompanyID=" + this.globals.ClientID + "?LocationID=" + this.globals.LocationID;

      window.open(link, "_blank");
    }
  }
}
