import { HttpHeaders } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { GlobalService } from 'app/shared/global.service';

@Injectable({
   providedIn: 'root'
})
export class UserDashboardService {
   isSessionVaild = true;

   constructor(private http: HttpClient, public router: Router, private globals: GlobalService) { this.isSessionVaild = true; }

   //  Gets Dashboard User List
   GetDashboardUserList(ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'UserDashboard/GetDashboardUserList?clientID=' + ClientID
         + '&locationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }

   //  Gets Dashboard User List
   GetAllUserList(ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'UserDashboard/GetAllUserList?clientID=' + ClientID
         + '&locationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }


   // This gives call to Get method from UserController
   ViewDashboardUser(id) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'UserDashboard/ViewDashboardUser?id=' + id, { headers: new HttpHeaders(customHeaders) })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }

   // This function Saves/updates data of User
   // Param: Object of User
   // This gives call to post method from UserController
   saveUserDashboard(UserDashboard) {
      const customHeaders = this.globals.GetCustomHeadersForPost();
      return this.http.post<any>(environment.apiUrl + 'UserDashboard', UserDashboard, { headers: new HttpHeaders(customHeaders) })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }

   GetAllUserListMsg(ClientID, LocationID, UserID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'UserDashboard/GetAllUserListMsg?ClientID=' + ClientID
         + '&LocationID=' + LocationID + '&UserID=' + UserID, { headers: new HttpHeaders(customHeaders) })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }
}
