import { HttpHeaders } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { GlobalService } from 'app/shared/global.service';

@Injectable({
   providedIn: 'root'
})
export class DashboardV1Service {

   constructor(private http: HttpClient, public router: Router, private globals: GlobalService) { }
   // Getting discharge type wise count
   Getpatientwisedoctortype(ResultType, FromDate, ToDate, ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'DashBord/Getpatientwisedoctortype?resultType=' + ResultType +
         '&fromDate=' + FromDate + '&toDate=' + ToDate + '&clientID=' + ClientID +
         '&locationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }
   // Getting Doctor Wisre Patient Count
   Getdashboard_DoctorWisrePatientCount(OpIpFlag, ResultType, FromDate, ToDate, ClientID, LocationID, UserID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'DashBord/Getdashboard_DoctorWisrePatientCount?OpIpFlag=' + OpIpFlag +
         '&resultType=' + ResultType + '&fromDate=' + FromDate + '&toDate=' + ToDate + '&clientID=' + ClientID +
         '&locationID=' + LocationID + '&userID=' + UserID, { headers: new HttpHeaders(customHeaders) })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Doctor Wisre Patient Count
   Getdashboard_DoctorwiseAdmittedPatientCount(OpIpFlag, ResultType, FromDate, ToDate, ClientID, LocationID, UserID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'DashBord/Getdashboard_DoctorwiseAdmittedPatientCount?OpIpFlag=' + OpIpFlag +
         '&resultType=' + ResultType + '&fromDate=' + FromDate + '&toDate=' + ToDate + '&clientID=' + ClientID +
         '&locationID=' + LocationID + '&userID=' + UserID, { headers: new HttpHeaders(customHeaders) })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Department Wise Patient Count
   GetDepartmentWisePatientCount(ResultType, FromDate, ToDate, ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'DashBord/GetDepartmentWisePatientCount?resultType=' + ResultType +
         '&fromDate=' + FromDate + '&toDate=' + ToDate + '&clientID=' + ClientID +
         '&locationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Opd Ipd count
   Getdashboard_OpdIpdCount(OpIpFlag, ResultType, FromDate, ToDate, ClientID, LocationID, UserID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'DashBord/Getdashboard_OpdIpdCount?opIpFlag=' + OpIpFlag + '&resultType=' + ResultType +
         '&fromDate=' + FromDate + '&toDate=' + ToDate + '&clientID=' + ClientID +
         '&locationID=' + LocationID + '&userID=' + UserID, { headers: new HttpHeaders(customHeaders) })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting OPD IPD Collection count
   Getdashboard_OPD_IPD_Collection(OpIpFlag, ResultType, FromDate, ToDate, ClientID, LocationID, UserID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'DashBord/Getdashboard_OPD_IPD_Collection?opIpFlag=' + OpIpFlag + '&resultType=' + ResultType +
         '&fromDate=' + FromDate + '&toDate=' + ToDate + '&clientID=' + ClientID +
         '&locationID=' + LocationID + '&UserID=' + UserID, { headers: new HttpHeaders(customHeaders) })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting OPD IPD Collection count
   Getdashboard_OPD_IPD_Advance_Collection(OpIpFlag, ResultType, FromDate, ToDate, ClientID, LocationID, UserID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'DashBord/Getdashboard_OPD_IPD_Advance_Collection?opIpFlag=' + OpIpFlag + '&resultType=' + ResultType +
         '&fromDate=' + FromDate + '&toDate=' + ToDate + '&clientID=' + ClientID +
         '&locationID=' + LocationID + '&UserID=' + UserID, { headers: new HttpHeaders(customHeaders) })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Opd Ipd Outstanding count
   Getdashboard_OpdIpd_Outstanding(OpIpFlag, ResultType, FromDate, ToDate, ClientID, LocationID, UserID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'DashBord/Getdashboard_OpdIpd_Outstanding?opIpFlag=' + OpIpFlag +
         '&resultType=' + ResultType + '&fromDate=' + FromDate + '&toDate=' + ToDate + '&clientID=' + ClientID +
         '&locationID=' + LocationID + '&UserID=' + UserID, { headers: new HttpHeaders(customHeaders) })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Opd Ipd Registration
   Getdashboard_OpdIpdRegistration(OpIpFlag, ResultType, FromDate, ToDate, ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'DashBord/Getdashboard_OpdIpdRegistration?opIpFlag=' + OpIpFlag +
         '&resultType=' + ResultType + '&fromDate=' + FromDate + '&toDate=' + ToDate + '&clientID=' + ClientID +
         '&locationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Opd Ipd Patient Source
   Getdashboard_OpdIpd_PatientSourceCount(OpIpFlag, ResultType, FromDate, ToDate, ClientID, LocationID, UserID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'DashBord/Getdashboard_OpdIpd_PatientSourceCount?opIpFlag=' + OpIpFlag +
         '&resultType=' + ResultType + '&fromDate=' + FromDate + '&toDate=' + ToDate + '&clientID=' + ClientID +
         '&locationID=' + LocationID + '&UserID=' + UserID, { headers: new HttpHeaders(customHeaders) })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Diet
   GetdashboardDietBalancing(OpIpFlag, ResultType, FromDate, ToDate, ClientID, LocationID, UserID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'DashBord/GetdashboardDietBalancing?opIpFlag=' + OpIpFlag +
         '&resultType=' + ResultType + '&fromDate=' + FromDate + '&toDate=' + ToDate + '&clientID=' + ClientID +
         '&locationID=' + LocationID + '&UserID=' + UserID, { headers: new HttpHeaders(customHeaders) })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Diet Treatment
   GetdashboardTreatmentBalancing(OpIpFlag, ResultType, FromDate, ToDate, ClientID, LocationID, UserID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'DashBord/GetdashboardTreatmentBalancing?opIpFlag=' + OpIpFlag +
         '&resultType=' + ResultType + '&fromDate=' + FromDate + '&toDate=' + ToDate + '&clientID=' + ClientID +
         '&locationID=' + LocationID + '&UserID=' + UserID, { headers: new HttpHeaders(customHeaders) })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Opd Ipd Service Wise Revenue
   Getdashboard_OpdIpd_ServiceWiseRevenue(OpIpFlag, ResultType, FromDate, ToDate, ClientID, LocationID, UserID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'DashBord/Getdashboard_OpdIpd_ServiceWiseRevenue?opIpFlag=' + OpIpFlag +
         '&resultType=' + ResultType + '&fromDate=' + FromDate + '&toDate=' + ToDate + '&clientID=' + ClientID +
         '&locationID=' + LocationID + '&UserID=' + UserID, { headers: new HttpHeaders(customHeaders) })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Ipd Discharge count
   GetIpdDischargeCount(ResultType, FromDate, ToDate, ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'DashBord/GetIpdDischargeCount?resultType=' + ResultType +
         '&fromDate=' + FromDate + '&toDate=' + ToDate + '&clientID=' + ClientID +
         '&locationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Ipd  Gender Wise Count
   GetIpdGenderWiseCount(OpIpFlag, ResultType, FromDate, ToDate, ClientID, LocationID, UserID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'DashBord/GetIpdGenderWiseCount?opIpFlag=' + OpIpFlag + '&resultType=' + ResultType +
         '&fromDate=' + FromDate + '&toDate=' + ToDate + '&clientID=' + ClientID +
         '&locationID=' + LocationID + '&UserID=' + UserID, { headers: new HttpHeaders(customHeaders) })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Ipd Age Distribution Count
   GetIpdAgeDistributionCount(OpIpFlag, ResultType, FromDate, ToDate, ClientID, LocationID, UserID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'DashBord/GetIpdAgeDistributionCount?opIpFlag=' + OpIpFlag + '&resultType=' + ResultType +
         '&fromDate=' + FromDate + '&toDate=' + ToDate + '&clientID=' + ClientID +
         '&locationID=' + LocationID + '&UserID=' + UserID, { headers: new HttpHeaders(customHeaders) })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Opd Ipd Patient Last 12 Month
   GetDashboard_Opd_Ipd_PatientLast12Month(OpIpFlag, ResultType, FromDate, ToDate, ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'DashBord/GetDashboard_Opd_Ipd_PatientLast12Month?opIpFlag=' + OpIpFlag +
         '&resultType=' + ResultType + '&fromDate=' + FromDate + '&toDate=' + ToDate + '&clientID=' + ClientID +
         '&locationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Avg Transaction per day
   GetAvgTransactionPerDay(OpIpFlag, FromDate, ToDate, ClientID, LocationID, UserID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'DashBord/GetAvgTransactionPerDay?opIpFlag=' + OpIpFlag +
         '&fromDate=' + FromDate + '&toDate=' + ToDate + '&clientID=' + ClientID +
         '&locationID=' + LocationID + '&UserID=' + UserID, { headers: new HttpHeaders(customHeaders) })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Doctor Wisre Patient Count
   GetIpdOpdRefundCount(OpIpFlag, ResultType, FromDate, ToDate, ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'DashBord/GetIpdOpdRefundCount?OpIpFlag=' + OpIpFlag +
         '&resultType=' + ResultType + '&fromDate=' + FromDate + '&toDate=' + ToDate + '&clientID=' + ClientID +
         '&locationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Doctor Wisre Patient Count
   GetUserDashboardKPIlist(UserID, ApplicableFor, ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'DashBord/GetUserDashboardKPIlist?UserID=' + UserID +
         '&ApplicableFor=' + ApplicableFor + '&ClientID=' + ClientID +
         '&LocationID=' + LocationID, { headers: customHeaders })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Doctor Wisre Patient Count
   UserCollectionDetails(UserName, SelectedDate, ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'DashBord/UserCollectionDetails?userName=' + UserName +
         '&selectedDate=' + SelectedDate + '&clientID=' + ClientID + '&locationID=' + LocationID,
         { headers: new HttpHeaders(customHeaders) })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }

   GetKPIDetailsResult() {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'DashBord/GetKPIDetailsResult', { headers: new HttpHeaders(customHeaders) })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }

   getUser(ClientID, ComponentID, LocationID, UserID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'internalchat/GetAll?ClientID=' + ClientID
         + '&ComponentID=' + ComponentID + '&LocationID=' + LocationID + '&UserID=' + UserID, { headers: new HttpHeaders(customHeaders) })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }

   getInternalChatById(id) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'InternalChat/Get?id=' + id, { headers: new HttpHeaders(customHeaders) })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }

   saveUser(internalchat) {
      const customHeaders = this.globals.GetCustomHeadersForPost();
      return this.http.post<any>(environment.apiUrl + 'internalchat', internalchat, { headers: new HttpHeaders(customHeaders) })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }

   DeactivateInternalChat(InternalChatID, InternalChatDetailsID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.delete<any>(environment.apiUrl + 'InternalChat/Delete?InternalChatID=' + InternalChatID
         + '&InternalChatDetailsID=' + InternalChatDetailsID, { headers: new HttpHeaders(customHeaders) })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }

   GetInternalChatCount(UserID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'InternalChat/GetInternalChatCount?UserID=' + UserID, { headers: new HttpHeaders(customHeaders) })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Visit Type Count
   GetdashboardVisitTypeCount(ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'DashBord/VisitTypePatientCount?ClientID=' + ClientID +
         '&LocationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Admitted Patient Summary
   GetAdmittedPatientSummary(ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'DashBord/GetAdmittedPatientSummary?ClientID=' + ClientID +
         '&LocationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }
   GetOutstandingReport(fromDate, toDate, ClientID,LocationID,OutstandingresultType)
   {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'DashBord/GetOutstandingReport?fromDate='+ fromDate + '&toDate=' + toDate + '&ClientID=' + ClientID +
         '&LocationID=' + LocationID + '&OutstandingresultType=' + OutstandingresultType, { headers: new HttpHeaders(customHeaders) })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }
}
