export const environment = {
  production: true,
  // apiUrl: 'http://192.168.100.11/carme/api/', // Prime Care local
  // apiUrl: 'http://103.232.237.202:81/carme/api/', // Prime Care global
  // apiUrl: 'http://192.168.100.11/carme/api/', // Mac Care local
  // apiUrl: 'http://122.169.100.23:81/carme/api/', // Mac Care global
  // apiUrl: 'http://ACC1/carme/api/', // Mac Care New
  apiUrl: 'https://epionhealth.in/carme/api/', // Live Server For India
  // apiUrl: 'http://om.epionhealth.in/carme/api/' // Latest Live Server For oman
  // apiUrl: 'https://demo.epionhealth.in/carme/api/' // Latest Live Server For demo
  // apiUrl: 'https://test.epionhealth.in/carme/api/' // Latest Testing Server
  // apiUrl: 'http://path.epionhealth.in/carme/api/' // Pathology Server
  // apiUrl: 'http://pharma.epionhealth.in/carme/api/' // Pharmacy Server
  // apiUrl: 'https://gw.epionhealth.in/carme/api/' // Grand world Elder
  // apiUrl: 'http://192.168.100.32/carme/api/', // Sobeh local
  // apiUrl: 'http://127.0.0.1/carme/api/', //  local IIS
  // apiUrl: 'http://TalekarServer/carme/api/', //  local Talekar Server
  // apiUrl: 'http://PadwalServer/carme/api/', //  local Padwal Server
  // apiUrl: 'http://SOMANIHOSPITAL/carme/api/', //  local Padwal Server
  // apiUrl: 'http://SHAHAHOSPITAL/carme/api/', //  local SHAHA HOSPITAL
  // apiUrl: 'http://DeshmukhHospital/carme/api/', //  local Deshmukh Hospital
  // apiUrl: 'http://192.168.1.10/carme/api/', // Credence Hospital Mumbai
  // apiUrl: 'http://localhost/carme/api/', // Varad Netralaya
  // apiUrl: 'http://APP1/carme/api/', // Vadant Shirur
  // apiUrl: 'https://nca.epionhealth.in/carme/api/' // NCA
  // apiUrl: 'https://phc.epionhealth.in/carme/api/' // PHC
  // ng build --aot --watch --configuration --outputHashing=all
};
