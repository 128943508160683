import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class GlobalService {

    subject = new BehaviorSubject(false);

    public title = "";
    public ClientName: string = "";
    public ClientAddress: string = "";
    public HasClientInfo: boolean = false;
    public MultiClientLocation: boolean = false;
    public DietTreatmentType: string = "";
    public ClientID: number = 0;
    public LocationID: number = 0;
    public RegNo: string = "";
    public ComponentID: number = 0;
    public UserID: number = 0;
    public LoginTemplate: string = "";
    public UserName: string = "";
    public LoginTemplateName: string = "";
    public IsDocterzClient: boolean = false; // Is Docterz Client
    public registrationVisible = false; // OPD / IPD Registration
    public admissionVisible = false; // Admission
    public visitMarkVisible = false; // Visit Mark
    public MenuComponentAuthentication = [];
    public VisibleComponentMenu = {};
    public VisibleComponentMenuID = {};
    public ShowClientLocationPopUp: boolean = false;
    public casePaperVisible: boolean = false; // OPD / IPD Case Paper
    public billVisible: boolean = false;
    public recordConsentVisible: boolean = false;
    public ipdBillVisible: boolean = false; // IPD Billing
    public ipdShortBillVisible: boolean = false; // IPD Short Billing
    public ipdBillReconciliationVisible: boolean = false; // IPD Billing Reconciliation
    public bedTransferVisible: boolean = false; // Bed Transfer
    public ipdBillPaymentVisible: boolean = false; // IPD Bill Payment
    public cashlessVisible: boolean = false; // Cashless
    public cashlessDummyBillVisible: boolean = false; //CashlessDummyBill
    public dischargeVisible: boolean = false; // Discharge
    public IpdadvanceVisible: boolean = false; // OPD / IPD Advance
    public OpdadvanceVisible: boolean = false; // OPD / IPD Advance
    public IpdrefundVisible: boolean = false; // OPD / IPD Refund
    public OpdrefundVisible: boolean = false; // OPD / IPD Refund
    public nursingObservationVisible: boolean = false; // OPD / IPD Nursing Observation
    public radiologyResultEntryVisible: boolean = false; // Radiology Result Entry
    public pathologyResultEntryVisible: boolean = false; // Pathology Result Entry
    public opdBillReconciliationVisible: boolean = false; // OPD Billing Reconciliation
    public opdInvoicePaymentVisible: boolean = false; // OPD Invoice Payment
    public doctorRequestVisible: boolean = false; // Doctor Request
    public packageChargesVisible: boolean = false;  // package Charges
    public TreatmentAndDietDashboardVisible = false; // Treatment And Diet Dashboard
    public PatientTreatmentAndDietVisible = false; //patient  Treatment And Diet
    public imageProcessVisible = false;  // image Process Visible
    public pharmacyBillUpdationVisible: boolean = false;  // pharmacy Charges
    public pharmacyBillRecoVisible: boolean = false;  // pharmacy Charges
    public SessionDatavisible: boolean = false; // Laser Sheet
    public pathologyReportVisible: boolean = false; // Pathology Report
    public opdReportVisible: boolean = false; // opd Report
    public ipdReportVisible: boolean = false; // ipd Report
    public commonReportVisible: boolean = false; // common Report
    public inventoryReportVisible: boolean = false; // opd Report
    public IsNewPatientRegistartionbtn: boolean = false; // Ipd New Patient Registartion button
    public IsCashlessSuperAdmin: boolean = false;
    public PagenationApplicable: boolean = false;
    public OpIpQueueDisplay: boolean = false;
    public freehandwritingVisible: boolean = false; // Free Hand Pdf Writting
    public GRNAmendmentVisible: boolean = false;
    public SalesAmendmentVisible: boolean = false;
    public AutoServiceChargesVisible: boolean = false; // Auto Added Service Charges
    public PolicyPaySwitchVisible: boolean = false; // Policy Pay Switch
    public MaterialIssueReturnVisible: boolean = false; // Material Issue Return
    public BillReconcilationType :string ="";

    public IsMultipleStore: boolean = false; // Is Client has multiple Store

    public today;
    public DateForComparing;
    public CurrentDateTime;
    public CurrentLocalDateTime;
    public TimeDifference;
    public CurrentDateTimeWithoutChanges;
    public SelectedPreviousDateTime;
    public PreviousDateTransaction = false;
    public BillID: number = 0;
    public BillType: string = "";
    public OpIpID: number = 0;
    public OpIpFlag: boolean = true;
    public LedgerType: string = "";
    public IsPrint: boolean = false;
    public FromDate: string = "";
    public ToDate: string = "";
    public LastVisitedComponent: string = "";
    public Class: string = "";
    public ReceiptBook: string = "";
    public SponsorType: string = "";
    public SelectedName: string = "";
    public GSM: string = "";

    public PatientID: number = 0;
    public DocterzPatientID: number = 0;
    public Gender: string = "";
    public DOB: string = "";
    public PatientName: string = "";
    public PrimaryParentName: string = "";
    public DocterzUHID: string = "";
    public ThirdPartyUHID: string = "";
    public GSM1: string = "";
    public SecondaryParentName: string = "";
    public SecondaryParentGSM: string = "";
    public PostalCode: string = "";
    public City: string = "";
    public Address: string = "";
    public DefaultStore: number = 0;
    public DefaultSubStore: number = 0;
    public LicenseFor: string = "";
    public patientPrescriptonVisible = false; // Patient Prescription
    public stockAdjustmentVisible = false; // stock Adjustment
    public MrpAdjustmentVisible = false;   // // Mrp Adjustment
    public PaymentReconciliationVisible = false;  // Payment Reconciliation
    public VaccinationVisible = false; // Vaccination
    public PaediatricCaseSheetVisible = false // Paediatric CaseSheet
    public ChangePasswordVisible = false;
    public VideoConferenceVisible: boolean = false;
    public EHRTemplateVisible: boolean = false;
    public InternalChatVisible = false;
    public imageuploadVisible: boolean = false;   // Image upload
    public ophthalVisible: boolean = false;   // ophthal
    public IsPasswordCorrect: boolean = false;
    public ChangeInternalChatCount: boolean = false;
    public MaxInternalChatDetailsID: number = 0;
    public CertificateVisible: boolean = false;
    public DiatPlanVisible: boolean = false; // Pathology Report
    public ExpireTime: Date;

    public IsClientSuperAdmin: boolean = false; // Is Client Super Admin
    public DoctorScreenAlert: boolean = false;

    public IsDiangnosticDeleteAuthority: boolean = false; // Is Diangnostic Delete Authority

    public LPOID: number = 0; // For LPO Selection

    messages: any = {};

    configurations: any = {};
    configurationsDetails = [];
    GeneralConfigurations: any = {};

    public LoginPatientID: number = 0;
    public LoginPatientName: string = "";
    public ClientReportPath: string = "";    // Client Report Path in configuration details
    public ClientSpecialReport: string = "";  // Client Special Report in configuration details

    // OPD
    public OPDCash: number = 0;
    public OPDCashSettlement: number = 0;
    public OPDInvoice: number = 0;
    public OPDInvoiceSettlement: number = 0;
    public OPDAdvance: number = 0;
    public OPDRefundBill: number = 0;
    public OPDRefundAdvance: number = 0;
    public OPDCashlessDummyBill: number = 0;

    // IPD
    public IPDCash: number = 0;
    public IPDCashSettlement: number = 0;
    public IPDInvoice: number = 0;
    public IPDInvoiceSettlement: number = 0;
    public IPDAdvance: number = 0;
    public IPDRefundBill: number = 0;
    public IPDRefundAdvance: number = 0;
    public IPDShortBill: number = 0;
    public IPDShortBillSettlement: number = 0;
    public IPDCashlessDummyBill: number = 0;
    public ReceiptBookForUnderID: number = 0;


    // Sales
    public SalesID: number = 0;
    public SalesCash: number = 0;
    public SalesInvoice: number = 0;
    public SalesBillSettlement: number = 0;
    public SalesReturn: number = 0;
    public salesRetunVisible: boolean = false;
    public SalesRefund: number = 0;
    public GoodsReceiptNotePayment: number = 0;
    // Goods Receipt Note

    public GRNID: number = 0;
    public GoodsReceiptNote: number = 0;
    public GoodsReceiptNoteReturn: number = 0;
    public goodsReceiptNoteRetunVisible: boolean = false;

    // Indent
    public Indent: number = 0;
    public AllowNewIndentWOoldIndent: number = 0;

    public externalPathologyBillingVisible: boolean = false; // External Pathology Billing
    public PathSampleRequestID: number = 0; // Pathology Sample ID
    public HospitalBelongsTo: string = "";
    public SampleType: string = "";
    public PathRequestFrom: string = "";
    public PathRequestBy: string = "";

    // Material Issue
    public IssueID: number = 0;
    public MaterialIssue: number = 0;
    public ReturnFromSubStore: number = 0;
    public returnFromSubStoreReturnVisible: boolean = false;
    public IssueToPatient = 0;
    public ReturnFromPatient = 0;

    // Material Consume
    public MaterialConsume: number = 0;
    public StockAdjustment: number = 0;
    public MrpAdjustment: number = 0;


    constructor(private toastrService: ToastrService) { }

    // Setting Default Values For Global Service
    SetDefaultValuesForGlobal() {
        this.title = "";
        this.ClientName = "";
        this.ClientAddress = "";
        this.HasClientInfo = false;
        this.MultiClientLocation = false;
        this.DietTreatmentType = "";
        this.ClientID = 0;
        this.LocationID = 0;
        this.RegNo = '';
        this.ComponentID = 0;
        this.UserID = 0;
        this.LoginTemplate = "";
        this.UserName = "";
        this.LoginTemplateName = "";
        this.IsDocterzClient = false; // Is Docterz Client
        this.registrationVisible = false; // OPD / IPD Registration
        this.admissionVisible = false;
        this.visitMarkVisible = false; // visit mark
        this.MenuComponentAuthentication = [];
        this.VisibleComponentMenu = {};
        this.VisibleComponentMenuID = {};
        this.ShowClientLocationPopUp = false;
        this.casePaperVisible = false; // OPD / IPD Case Paper
        this.billVisible = false;
        this.recordConsentVisible = false;
        this.ipdBillVisible = false; // IPD Billing
        this.ipdShortBillVisible = false; // IPD Short Billing
        this.ipdBillReconciliationVisible = false; // IPD Billing Reconciliation
        this.ipdBillPaymentVisible = false; // IPD Bill Payment
        this.bedTransferVisible = false; // Bed Transfer
        this.cashlessVisible = false; // Cashless
        this.cashlessDummyBillVisible = false; // Cashless Dummy Bill
        this.dischargeVisible = false; // Discharge
        this.IpdadvanceVisible = false; // OPD / IPD Advance
        this.OpdadvanceVisible = false; // OPD / IPD Advance
        this.IpdrefundVisible = false; // OPD / IPD Refund
        this.OpdrefundVisible = false; // OPD / IPD Refund
        this.nursingObservationVisible = false; // OPD /IPD Nursing Observation
        this.radiologyResultEntryVisible = false; // Radiology Result Entry
        this.pathologyResultEntryVisible = false; // Pathology Result Entry
        this.opdBillReconciliationVisible = false; // OPD Bill Reconciliation
        this.opdInvoicePaymentVisible = false; // OPD Invoice Payment
        this.doctorRequestVisible = false; // Doctor Request
        this.packageChargesVisible = false;// packageCharges
        this.TreatmentAndDietDashboardVisible = false;
        this.PatientTreatmentAndDietVisible = false;
        this.imageProcessVisible = false;  // image Process Visible
        this.pharmacyBillUpdationVisible = false; // pharmacy Charges
        this.pharmacyBillRecoVisible = false; // pharmacy bill reconcilation

        this.SessionDatavisible = false; //  Session data for session services like laser sheet
        this.today = "";
        this.DateForComparing = "";
        this.CurrentDateTime = "";
        this.CurrentDateTimeWithoutChanges = "";
        this.SelectedPreviousDateTime = "";
        this.PreviousDateTransaction = false;
        this.BillID = 0;
        this.BillType = "";
        this.OpIpID = 0;
        this.OpIpFlag = true;
        this.LedgerType = "";
        this.IsPrint = false;
        this.FromDate = "";
        this.ToDate = "";
        this.LastVisitedComponent = "";
        this.Class = "";
        this.ReceiptBook = "";
        this.SponsorType = "";
        this.SelectedName = "";
        this.GSM = "";
        this.PatientID = 0;
        this.DocterzPatientID = 0;
        this.Gender = "";
        this.DOB = "";
        this.PatientName = "";
        this.PrimaryParentName = "";
        this.DocterzUHID = "";
        this.ThirdPartyUHID = "";
        this.GSM1 = "";
        this.SecondaryParentName = "";
        this.SecondaryParentGSM = "";
        this.PostalCode = "";
        this.City = "";
        this.Address = "";
        this.DefaultStore = 0;
        this.DefaultSubStore = 0;
        this.LicenseFor = "";
        this.IsPasswordCorrect = false;
        this.ChangeInternalChatCount = false;
        this.MaxInternalChatDetailsID = 0;
        this.ExpireTime = new Date();
        this.IsClientSuperAdmin = false; // Is Client Super Admin
        this.DoctorScreenAlert = false;
        this.IsDiangnosticDeleteAuthority = false // Is Diangnostic Delete Authority
        this.messages = {};
        this.configurations = {};
        this.patientPrescriptonVisible = false; // Patient Prescription
        this.stockAdjustmentVisible = false; // stock Adjustment
        this.MrpAdjustmentVisible = false; //mrp adjustment
        this.PaymentReconciliationVisible = false;  // Payment Reconciliation
        this.VaccinationVisible = false; // Vaccination
        this.PaediatricCaseSheetVisible = false;
        this.ChangePasswordVisible = false;
        this.VideoConferenceVisible = false;
        this.EHRTemplateVisible = false;
        this.InternalChatVisible = false;
        this.imageuploadVisible = false;   // Image upload
        this.ophthalVisible = false;
        this.CertificateVisible = false;
        this.DiatPlanVisible = false;
        this.LPOID = 0; // For LPO Selection
        this.freehandwritingVisible = false; // Free Hand Pdf Writting
        this.GRNAmendmentVisible = false;
        this.SalesAmendmentVisible = false;
        this.AutoServiceChargesVisible = false; // Auto Added Service Charges
        this.PolicyPaySwitchVisible = false;
        this.BillReconcilationType = "";
        this.IsMultipleStore = false; // Is Client has multiple Store
        // For Login Patient
        this.LoginPatientID = 0;
        this.LoginPatientName = "";

        // OPD
        this.OPDCash = 0;
        this.OPDCashSettlement = 0;
        this.OPDInvoice = 0;
        this.OPDInvoiceSettlement = 0;
        this.OPDAdvance = 0;
        this.OPDRefundBill = 0;
        this.OPDRefundAdvance = 0;
        this.OPDCashlessDummyBill = 0;

        // IPD
        this.IPDCash = 0;
        this.IPDCashSettlement = 0;
        this.IPDInvoice = 0;
        this.IPDInvoiceSettlement = 0;
        this.IPDAdvance = 0;
        this.IPDRefundBill = 0;
        this.IPDRefundAdvance = 0;
        this.IPDShortBill = 0;
        this.IPDShortBillSettlement = 0;
        this.IPDCashlessDummyBill = 0;
        this.ReceiptBookForUnderID = 0;

        // Sales
        this.SalesID = 0;
        this.SalesCash = 0;
        this.SalesInvoice = 0;
        this.SalesBillSettlement = 0;
        this.GoodsReceiptNotePayment = 0;
        this.salesRetunVisible = false;
        this.SalesRefund = 0;

        // Goods Receipt Note
        this.GRNID = 0;
        this.GoodsReceiptNote = 0;
        this.GoodsReceiptNoteReturn = 0;
        this.goodsReceiptNoteRetunVisible = false;

        // Indent
        this.Indent = 0;
        this.AllowNewIndentWOoldIndent = 0;

        this.externalPathologyBillingVisible = false; // External Pathology Billing
        this.PathSampleRequestID = 0; // Pathology Sample ID
        this.HospitalBelongsTo = "";
        this.SampleType = ""; // Sample Type
        this.PathRequestFrom = "";
        this.PathRequestBy = "";

        this.pathologyReportVisible = false; // Pathology Report
        this.opdReportVisible = false; // opd report
        this.ipdReportVisible = false; // ipd report
        this.commonReportVisible = false; // common report
        this.inventoryReportVisible = false; // Inventory report
        this.IsNewPatientRegistartionbtn = false; // Ipd New Patient Registartion button
        this.IsCashlessSuperAdmin = false;
        this.OpIpQueueDisplay = false;
        this.PagenationApplicable = false;

        // IssueToSubStore
        this.IssueID = 0;
        this.MaterialIssue = 0;
        this.ReturnFromSubStore = 0;
        this.returnFromSubStoreReturnVisible = false;
        this.IssueToPatient = 0;
        this.ReturnFromPatient = 0;

        // Material Consume
        this.MaterialConsume = 0;
        // StockAdjustment
        this.StockAdjustment = 0;
        //MrpAdjustment
        this.MrpAdjustment = 0;

    }

    // Getting Custom Headers For Get
    GetCustomHeadersForGet() {
        const customHeaders = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET'
        };
        return customHeaders;
    }

    // Getting Custom Headers For Post
    GetCustomHeadersForPost() {
        const customHeaders = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'POST'
        };
        return customHeaders;
    }

    // Getting Custom Headers For Delete
    GetCustomHeadersForDelete() {
        const customHeaders = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'DELETE'
        };
        return customHeaders;
    }

    // Getting Custom Headers For Post form data
    GetCustomHeadersForPostFormData() {
        const customHeaders = {
            'Content-Type': 'multipart/form-data',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'POST'
        };
        return customHeaders;
    }

    // Setting new boolean value to subject
    setSubjectBooleanValue(param: boolean) {
        this.subject.next(param);
    }

    // Showing messages using toastr and type of message
    ShowToastrMessage(type: string, message: string) {
        if (type === 'success') {
            this.toastrService.success(this.messages["msg_Success"]);
        } else if (type === 'warning') {
            this.toastrService.warning(message == "delete" ? this.messages["msg_Delete"] : message);
        } else if (type === 'error') {
            this.toastrService.error(this.messages["msg_Exception"]);
        }
    }
}
